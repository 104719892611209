//! This list must also be updates in API
export const TimeZones = [
  {
    timezone: 'Africa/Johannesburg en-ZA',
    name: 'English (South Africa)',
    code: 'en-ZA',
    apiCode: 'en_ZA',
    notificationCalendar: 'za',
  },
  {
    timezone: 'Africa/Johannesburg zu-ZA',
    name: 'Zulu (South Africa)',
    code: 'zu-ZA',
    apiCode: 'zu_ZA',
    notificationCalendar: 'za',
  },
  {
    timezone: 'Europe/Amsterdam nl-NL',
    name: 'Dutch (Nederlands)',
    code: 'nl-NL',
    apiCode: 'nl_NL',
    notificationCalendar: 'nl',
  },
  {
    timezone: 'Europe/Berlin de-DE',
    name: 'Deutsch (Germany)',
    code: 'de-DE',
    apiCode: 'de_DE',
    notificationCalendar: 'de',
  },
  {
    timezone: 'Europe/Copenhagen da-DK',
    name: 'Dansk (Danmark)',
    code: 'da-DK',
    apiCode: 'da_DK',
    notificationCalendar: 'dk',
  },
  {
    timezone: 'Europe/Helsinki fi-FI',
    name: 'Suomi (Finland)',
    code: 'fi-FI',
    apiCode: 'fi_FI',
    notificationCalendar: 'fi',
  },
  {
    timezone: 'Europe/Madrid', //! en-US changed to Spain Timezone as requested!
    name: 'English (United States)	',
    code: 'en-US',
    apiCode: 'en_US',
    notificationCalendar: 'en',
  },
  {
    timezone: 'Europe/Madrid es-ES',
    name: 'Español (Spain)',
    code: 'es-ES',
    apiCode: 'es_ES',
    notificationCalendar: 'es',
  },
  {
    timezone: 'Europe/Oslo nn-NO',
    name: 'Norwegian (Norway)',
    code: 'nn-NO',
    apiCode: 'nn_NO',
    notificationCalendar: 'no',
  },
  {
    timezone: 'Europe/Stockholm sv-SE',
    name: 'Swedish (Sweden)',
    code: 'sv-SE',
    apiCode: 'sv_SE',
    notificationCalendar: 'se',
  },
  {
    timezone: 'Europe/Athens el-GR',
    name: 'Ελληνικά (Greek)',
    code: 'el-GR',
    apiCode: 'el_GR',
    notificationCalendar: 'gr',
  },
  {
    timezone: 'Europe/Lisbon pt-PT',
    name: 'Português (Portughese)',
    code: 'pt-PT',
    apiCode: 'pt_PT',
    notificationCalendar: 'pt',
  },
  {
    timezone: 'Europe/Vienna de-AT',
    name: 'German (Austria)',
    code: 'de-AT',
    apiCode: 'de_AT',
    notificationCalendar: 'de',
  },
  {
    timezone: 'Europe/Zurich de-CH',
    name: 'German (Switzerland)',
    code: 'de-CH',
    apiCode: 'de_CH',
    notificationCalendar: 'de',
  },
  {
    timezone: 'Europe/Zurich it-CH',
    name: 'Italian (Switzerland)',
    code: 'it-CH',
    apiCode: 'it_CH',
    notificationCalendar: 'it',
  },
  {
    timezone: 'Europe/Zurich fr-CH',
    name: 'French (Switzerland)',
    code: 'fr-CH',
    apiCode: 'fr_CH',
    notificationCalendar: 'fr',
  },
  {
    timezone: 'Greenwich en-GB',
    name: 'English (United Kingdom)',
    code: 'en-GB',
    apiCode: 'en_GB',
    notificationCalendar: 'gb',
  },
  {
    timezone: 'Asia/Jerusalem he-IL',
    name: 'Hebrew (Israel)',
    code: 'he-IL',
    apiCode: 'he_IL',
    notificationCalendar: 'il',
  },
]
