import { useQuery } from '@apollo/client'
import Autocomplete from '@mui/material/Autocomplete'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import Grid from '@mui/material/Grid'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import TextField from '@mui/material/TextField'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import RecurrenceInputForm from './RecurrenceInputForm'
import ColorSelector from '../../components/ColorSelector'
import GraphqlError from '../../components/GraphqlError'
import Loading from '../../components/Loading'
import MultipleSelect from '../../components/MultiSelect'
import ImageMediaField from '../../components/files/ImageMediaField'
import LocationField from '../../components/forms/LocationField'
import {
  EventAccessType,
  getOrganizersQuery,
  getOrganizersQueryVariables,
  OrganizersDataFragment,
} from '../../generated/graphql'
import { getAllLanguagesFromISO } from '../../helpers/Languages'
import Organizers from '../../schema/Organizers'

interface Props {
  state: any
  shortVersion?: boolean
  setState: (any) => any
  error?: Error
}

const EventTypeForm: React.FC<Props> = (props) => {
  const { state, setState, shortVersion, error } = props
  const { t } = useTranslation()
  const {
    loading: organizersLoading,
    error: organizersError,
    data: organizersData,
  } = useQuery<getOrganizersQuery, getOrganizersQueryVariables>(Organizers.GET_ORGANIZERS)
  const organizersRawData = organizersData?.organizers
  const organizersList = useMemo(() => {
    const orgList = {}
    if (organizersRawData) {
      organizersRawData.reduce((acc, i) => {
        acc[i.id] = i
        return acc
      }, orgList)
    }
    return orgList
  }, [organizersRawData])
  const allLanguages = useMemo(() => getAllLanguagesFromISO(), [])
  const handleChange = (attrName: string, value: any) => {
    setState({ ...state, [attrName]: value })
  }
  const handleChangeMultiple = (data: Record<string, any>) => {
    setState({ ...state, ...data })
  }
  const hasDupError = error?.message.includes('ER_DUP_ENTRY')
  return (
    <Grid container spacing={shortVersion ? 0 : 2}>
      <Grid item xs={12} sm={12} md>
        {!shortVersion && (
          <>
            <TextField
              variant="standard"
              autoFocus
              required
              label={t('Name')}
              type="text"
              value={state.name}
              onChange={(e) => handleChange('name', e.target.value)}
              fullWidth
            />
            <TextField
              variant="standard"
              label={t('Code')}
              required
              type="text"
              value={state.code}
              onChange={(e) => handleChange('code', e.target.value)}
              fullWidth
              error={hasDupError}
              helperText={hasDupError && 'Code already exists'}
            />
          </>
        )}
        <FormControl fullWidth>
          <FormControlLabel
            label={t('Allow Attendance Registration')}
            labelPlacement={'end'}
            control={<Checkbox />}
            checked={!!state.hasAttendeeRegistration}
            onChange={() =>
              setState((prev: any) => ({
                ...prev,
                hasAttendeeRegistration: !prev.hasAttendeeRegistration,
              }))
            }
          />
        </FormControl>
        <FormControl fullWidth>
          <FormControlLabel
            label={t('Online Event')}
            labelPlacement={'end'}
            control={<Checkbox />}
            checked={!!state.isOnline}
            onChange={() =>
              setState((prev: any) => ({
                ...prev,
                isOnline: !prev.isOnline,
              }))
            }
          />
        </FormControl>
        <GraphqlError error={organizersError} />
        {!organizersLoading && (
          <Autocomplete<OrganizersDataFragment, true>
            multiple
            id="tags-standard"
            value={state.organizersIds.map((i) => organizersList[i])}
            onChange={(event, v) => {
              handleChange(
                'organizersIds',
                v.map((i) => (typeof i === 'string' ? i : i.id)),
              )
            }}
            options={organizersRawData}
            renderOption={(propsR, option) => {
              return (
                <span {...propsR} key={option.id}>
                  {option.name}
                </span>
              )
            }}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
            renderInput={(params) => <TextField variant="standard" {...params} label={t('Organizers')} />}
          />
        )}
        {organizersLoading && <Loading />}

        {!shortVersion && (
          <TextField
            variant="standard"
            label={t('Title')}
            required
            type="text"
            value={state.title}
            onChange={(e) => handleChange('title', e.target.value)}
            fullWidth
          />
        )}
        {!shortVersion && (
          <TextField
            variant="standard"
            label={t('Description')}
            type="text"
            multiline
            value={state.description || ''}
            onChange={(e) => handleChange('description', e.target.value)}
            fullWidth
          />
        )}
        <MultipleSelect
          label={t('Event Language(s)')}
          value={state.language}
          options={allLanguages}
          setValue={(value) => {
            handleChange('language', value)
          }}
        />
        <TextField
          variant="standard"
          autoFocus
          label={t('Location Name')}
          type="text"
          value={state.locationName || ''}
          onChange={(e) => setState({ ...state, locationName: e.target.value })}
          fullWidth
        />
        <LocationField location={state.location} setLocation={(locationData) => handleChangeMultiple(locationData)} />
        {!shortVersion && (
          <TextField
            variant="standard"
            label={t('Duration (Minutes)')}
            inputProps={{ min: 0 }}
            type="number"
            value={state.duration || 0}
            onChange={(e) => handleChange('duration', parseInt(e.target.value) || null)}
            fullWidth
          />
        )}
        <TextField
          variant="standard"
          label={t('Max number of attendees')}
          inputProps={{ min: 0 }}
          type="number"
          value={state.attendeeLimit || 0}
          onChange={(e) => handleChange('attendeeLimit', parseInt(e.target.value))}
          fullWidth
        />
        <FormControl>
          <FormLabel>{t('Access Type')}</FormLabel>
          <RadioGroup
            row
            value={state.accessType}
            id="access-type"
            onChange={(e) => handleChange('accessType', (e.target as EventTarget & HTMLInputElement).value)}
          >
            {Object.values(EventAccessType).map((val) => (
              <FormControlLabel key={val} value={val} control={<Radio />} label={t(val)} />
            ))}
          </RadioGroup>
        </FormControl>
        <div>
          {!shortVersion && (
            <RecurrenceInputForm
              allowStatic={false}
              onChange={(recurrence) => handleChange('recurrence', recurrence)}
              originalEvent={state}
              value={state.recurrence}
            />
          )}
        </div>

        {!shortVersion && (
          <FormControl>
            <FormLabel>{t('Color')}</FormLabel>
            <ColorSelector
              colorIndex={400}
              color={state.color}
              onChange={(newColor) => handleChange('color', newColor)}
            />
          </FormControl>
        )}
      </Grid>
      {!shortVersion && (
        <Grid item xs={12} sm={12} md={6}>
          <ImageMediaField
            fullWidth
            id="featured-image"
            idMedia={state.featuredImageId ?? undefined}
            onChange={(id) => setState({ ...state, featuredImageId: id })}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default EventTypeForm
